<template>
  <div>
    <div style="margin: 2em 0">
      <a-button-group>
        <a-button type="danger">
          <router-link :to="{ name: 'invoices-expense' }">
            <a-icon type="download" /> Новый расход</router-link>
        </a-button>
      </a-button-group>
    </div>
    <a-table
      :columns="columns"
      :rowKey="record => record.Id"
      :dataSource="data"
      :pagination="pagination"
      :loading="loading"
      bordered
      @change="handleTableChange"
    >
    <span slot="action" slot-scope="item">
      <a-button-group>
        <a-button type="primary" v-on:click="showDetails(item.InvoiceItems)" :title="item.Id">
          Кратко
        </a-button>
        <a-button type="default">
          <router-link :to="{ name: 'expense-details', params: { invoiceId: item.Id } }">
            Детально
          </router-link>
        </a-button>
      </a-button-group>
    </span>
    </a-table>
    <a-modal
      title="Детали фактуры"
      :visible="modalVisible"
      @cancel="closeModal"
      cancelText="Закрыть"
    >

      <a-table :columns="modalColumns" :data-source="modalData" size="small">
          <span slot="Price" slot-scope="item">
            {{ item.Price }}</span>
        <span slot="Total" slot-scope="item">
            {{ (item.Amount * item.Price).toFixed(2) }}</span>
      </a-table>
    </a-modal>
  </div>
</template>

<style>
.ant-modal-footer .ant-btn-primary {
  display: none;
}
.ant-modal-footer div .ant-btn-primary {
  display: none;
}
.ant-modal-body {
  padding: 0;
}
</style>

<script>
const modalColumns = [
  {
    title: 'Товар',
    dataIndex: 'Product.Name',
    key: 'Product.Name',
    scopedSlots: { customRender: 'Product.Name' },
  },
  {
    title: 'Количество',
    dataIndex: 'Amount',
    key: 'Amount',
    scopedSlots: { customRender: 'Amount' },
  },
  {
    title: 'Цена',
    scopedSlots: { customRender: 'Price' },
  },
  {
    title: 'Сумма',
    scopedSlots: { customRender: 'Total' },
  },
];

const mainColumns = [
  {
    title: 'Номер фактуры',
    dataIndex: 'InvoiceNumber',
    scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'Тип фактуры',
    className: 'InvoiceType',
    dataIndex: 'InvoiceType',
  },
  {
    title: 'Склад',
    className: 'warehouse',
    dataIndex: 'Warehouse.Name',
  },
  {
    title: 'Поставщик',
    className: 'vendor',
    dataIndex: 'Vendor.Name',
  },
  {
    title: 'Итог',
    className: 'Total',
    dataIndex: 'Total',
  },
  {
    title: '',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  data() {
    return {
      data: [],
      modalColumns,
      pagination: {},
      loading: false,
      page: 1,
      modalVisible: false,
      modalData: [],
      columns: mainColumns,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    showDetails(data) {
      this.modalVisible = true;
      this.modalData = data;
    },
    closeModal() {
      this.modalVisible = false;
    },
    handleTableChange(pagination, filters, sorter) {
      this.page = pagination.current;
      this.pagination = {
        ...this.pagination,
        current: pagination.current,
      };
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      const queries = {
        filter: {
          InvoiceType: 'output',
        },
        page: params.page,
      };
      const query = Object.keys(queries)
        .map((item) => {
          const element = queries[item];
          if (queries[item] === undefined) {
            return undefined;
          }
          if (typeof element === 'object') {
            return Object.keys(element).map((key) => element[key] && `filter[${key}]=${element[key]}`).join('&');
          }
          return `${item}=${element}`;
        })
        .filter((item) => item !== undefined)
        .join('&');
      this.$api.filterInvoices(query, (response) => {
        const { data, meta } = response.data;
        this.searching = false;
        this.data = data;
        this.pagination = {
          pageSize: meta.per_page,
          current: meta.current_page,
          total: meta.total,
        };
      });
    },
  },
};
</script>
